import React from "react";
import { IconProps } from "../../models";

const ArrowLeft = ({ color }: IconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42.26 10.53">
    <polyline points="5.26 10.53 0 5.26 5.26 0" fill={color} />
    <line
      x1="4.26"
      y1="5.26"
      x2="42.26"
      y2="5.26"
      fill="none"
      stroke={color}
      strokeMiterlimit="10"
      className="line"
    />
  </svg>
);

export default ArrowLeft;
