import React from "react";
import { graphql, Link } from "gatsby";
import { MODULE_MAPPING } from "../mapping";
import { Layout } from "../layouts";
import PageHeader from "../components/core/PageHeader";
import { Container } from "../components/core/Container/styled";
import ModuleWrapper from "../components/modules/ModuleWrapper";
import { ModulesWrapper } from "../components/modules/ModuleWrapper/styled";
import Pagination from "../components/core/Pagination";
import MetaData from "../components/projects/MetaData";

const ProjectPage = ({ data, pageContext }) => {
  const post = data.markdownRemark;
  const {
    modules,
    headline,
    description,
    title,
    architect,
    location,
    developer,
  } = post.frontmatter;
  const { previous, next } = pageContext;

  const showMeta = !!architect || !!location || developer;

  const testimonials = data.testimonials.edges.map(
    (edge) => edge.node.frontmatter
  );

  return (
    <Layout title={title}>
      <PageHeader title={headline} description={description}>
        {showMeta && (
          <MetaData
            architect={architect}
            location={location}
            developer={developer}
          />
        )}
      </PageHeader>
      <Container>
        <ModulesWrapper>
          {!!modules &&
            modules.map((module, index) => {
              const Module = MODULE_MAPPING[module.module];

              if (!Module) {
                return null;
              }
              return (
                <ModuleWrapper key={index}>
                  <Module
                    {...module}
                    testimonials={
                      module.module === "testimonial" ? testimonials : undefined
                    }
                  />
                </ModuleWrapper>
              );
            })}
        </ModulesWrapper>
        <Pagination previous={previous} next={next} />
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        headline
        description
        architect
        developer
        location
        tags
        modules {
          image
          align
          textAlign
          size
          altText
          module
          quote
          testimonial
          videoId
          configUrl
          loop
          muted
          testimonial
          iFrame
          imageOne {
            image
            altText
          }
          imageTwo {
            image
            altText
          }
          imageThree {
            image
            altText
          }
          caption
        }
      }
    }
    testimonials: allMarkdownRemark(
      filter: { frontmatter: { layout: { eq: "testimonial" } } }
    ) {
      edges {
        node {
          frontmatter {
            quote
            attribution
            company
          }
        }
      }
    }
  }
`;

export default ProjectPage;
