import styled from "styled-components";
import { Space, Typography, Breakpoints } from "../../../settings";
import { breakpointUp, breakpointDown } from "../../../util/breakpoint";

export const Wrapper = styled.article`
  margin-top: ${Space.xxLarge.rem};

  ${breakpointUp(Breakpoints.tabletPortrait.upper)`
    display: flex;
    justify-content: center;
  `}
`;

export const Title = styled.h3`
  margin-bottom: ${Space.small.rem};
  margin-top: 0;
`;

export const Item = styled.div`
  text-align: center;
  flex: 1;

  ${breakpointDown(Breakpoints.tabletPortrait.lower)`
    & + & {
      margin-top: ${Space.default.rem};
    }
  `}

  ${breakpointUp(Breakpoints.tabletPortrait.upper)`
    & + & {
      margin-left: ${Space.xxxLarge.rem};
    }
  `}
`;

export const Data = styled.p`
  margin: 0;

  font-size: ${Typography.default.rem};
`;
