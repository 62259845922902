import React from "react";
import { Link } from "gatsby";
import { Wrapper, PaginationLink, PaginationItem, Arrow, Text } from "./styled";
import { useInView } from "react-hook-inview";
import { Color } from "../../../settings";
import ArrowLeft from "../../icon/ArrowLeft";
import ArrowRight from "../../icon/ArrowRight";
import { fade } from "../../../transition";

export interface PaginationLinkModel {
  fields: {
    slug: string;
  };
  frontmatter: {
    title: string;
  };
}

interface PaginationProps {
  previous?: PaginationLinkModel;
  next?: PaginationLinkModel;
}

const Pagination = ({ previous, next }: PaginationProps) => {
  const [ref, isVisible] = useInView({
    unobserveOnEnter: true,
    threshold: 1,
  });

  return (
    <Wrapper ref={ref}>
      <PaginationItem isVisible={isVisible}>
        {!!next && (
          <PaginationLink
            exit={{
              length: 0.25,
              trigger: ({ exit, node }) =>
                fade({ exit, node, direction: "out" }),
            }}
            entry={{
              length: 0.25,
              trigger: ({ exit, node }) =>
                fade({ exit, node, direction: "in" }),
              delay: 0.25,
            }}
            to={next.fields.slug}
          >
            <Arrow>
              <ArrowLeft color={Color.black} />
            </Arrow>
            <Text>{next.frontmatter.title}</Text>
          </PaginationLink>
        )}
      </PaginationItem>
      <PaginationItem isVisible={isVisible}>
        {!!previous && (
          <PaginationLink
            exit={{
              length: 0.25,
              trigger: ({ exit, node }) =>
                fade({ exit, node, direction: "out" }),
            }}
            entry={{
              length: 0.25,
              trigger: ({ exit, node }) =>
                fade({ exit, node, direction: "in" }),
              delay: 0.25,
            }}
            to={previous.fields.slug}
          >
            <Arrow>
              <ArrowRight color={Color.black} />
            </Arrow>
            <Text>{previous.frontmatter.title}</Text>
          </PaginationLink>
        )}
      </PaginationItem>
    </Wrapper>
  );
};

export default Pagination;
