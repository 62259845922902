import React from "react";
import { Wrapper, Item, Title, Data } from "./styled";

interface MetaDataProps {
  architect?: string;
  developer?: string;
  location?: string;
}

const MetaData = ({ architect, developer, location }: MetaDataProps) => {
  return (
    <Wrapper>
      {!!architect && (
        <Item>
          <Title>Architect</Title>
          <Data>{architect}</Data>
        </Item>
      )}
      {!!developer && (
        <Item>
          <Title>Developer</Title>
          <Data>{developer}</Data>
        </Item>
      )}
      {!!location && (
        <Item>
          <Title>Location</Title>
          <Data>{location}</Data>
        </Item>
      )}
    </Wrapper>
  );
};

export default MetaData;
