import styled from "styled-components";
import TransitionLink from "gatsby-plugin-transition-link";
import {
  Color,
  Space,
  Typography,
  Easing,
  Breakpoints,
} from "../../../settings";
import { breakpointUp, breakpointDown } from "../../../util/breakpoint";
import { FOOTER_BREAKPOINT } from "../Footer/styled";

export const PAGINATION_BREAKPOINT = Breakpoints.mobileLarge;

export const Wrapper = styled.nav`
  margin-top: ${Space.xxLarge.rem};
  display: flex;
  justify-content: space-between;

  ${breakpointUp(FOOTER_BREAKPOINT.upper)`
    margin-top: ${Space.xxxLarge.rem};
  `}
`;

export const Arrow = styled.span`
  display: inline-block;
  width: 70px;
  font-family: sans-serif;
  flex-shrink: 0;

  .line {
    transform: scaleX(0.6);
    transition: transform ${Easing.slow};
  }

  svg {
    vertical-align: middle;
  }

  ${breakpointDown(PAGINATION_BREAKPOINT.lower)`
    margin-bottom: ${Space.default.rem};
  `}
`;

export const PaginationLink = styled(TransitionLink)`
  display: block;

  ${breakpointUp(PAGINATION_BREAKPOINT.upper)`
    display: flex;
    align-items: center;

    font-size: ${Typography.large.rem};
  `}

  font-size: ${Typography.medium.rem};
  color: ${Color.black};
  text-decoration: none;
`;

export const Text = styled.span`
  transition: transform ${Easing.default};
  display: block;
`;

interface PaginationItemProps {
  isVisible: boolean;
}

export const PaginationItem = styled.div<PaginationItemProps>`
  ${breakpointUp(PAGINATION_BREAKPOINT.upper)`
    display: flex;
    align-items: center;
  `}

  width: 40%;

  opacity: ${p => (p.isVisible ? 1 : 0)};
  transition: opacity ${Easing.slow}, transform ${Easing.slow};

  &:nth-child(odd) {
    transform: ${p =>
      p.isVisible ? `translateX(0)` : `translateX(${Space.xxLarge.rem})`};

    ${Arrow} {
      margin-right: ${Space.default.rem};
    }

    ${PaginationLink}:hover {
      ${breakpointUp(PAGINATION_BREAKPOINT.upper)`
        ${Text} {
          transform: translateX(${Space.default.rem});
        }
      `}

      .line {
        transform: scaleX(1.2);
      }
    }
  }

  &:nth-child(even) {
    transform: ${p =>
      p.isVisible ? `translateX(0)` : `translateX(-${Space.xxLarge.rem})`};

    justify-content: flex-end;
    text-align: right;

    ${PaginationLink} {
      justify-content: flex-end;

      &:hover {
        ${breakpointUp(PAGINATION_BREAKPOINT.upper)`
          ${Text} {
            transform: translateX(-${Space.default.rem});
          }
        `};

        .line {
          transform: scaleX(1.2);
        }
      }
    }

    ${Arrow} {
      margin-left: ${Space.default.rem};
      text-align: right;
      order: 2;
    }
  }
`;
