import styled from "styled-components";
import { Space, Typography, Weight } from "../../../settings";

interface WrapperProps {
  theme: {
    color: string;
    backgroundColor: string;
  };
}

export const Wrapper = styled.header<WrapperProps>`
  margin: 0 auto;
  padding: ${Space.xxLarge.number * 2}rem 0;

  color: ${p => p.theme.color};
  background-color: ${p => p.theme.backgroundColor};
`;

export const Description = styled.p`
  margin: 0;
  margin-top: ${Space.large.rem};

  font-size: ${Typography.large.rem};
  font-weight: ${Weight.regular};
  line-height: 120%;
`;

export const Title = styled.h1`
  margin: 0;

  font-size: ${Typography.large.rem};
`;
