import styled from "styled-components";
import { Space, Easing, Breakpoints } from "../../../settings";
import { ModuleSizeType } from "../../../types";
import { breakpointDown } from "../../../util/breakpoint";

interface WrapperProps {
  isVisible: boolean;
}

export const ModulesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Wrapper = styled.div<WrapperProps>`
  opacity: ${p => (p.isVisible ? 1 : 0)};
  transform: ${p =>
    p.isVisible ? `translateY(0)` : `translateY(${Space.xLarge.rem})`};
  transition: opacity ${Easing.slow}, transform ${Easing.slow};

  width: 100%;

  & + & {
    margin-top: ${Space.xxLarge.rem};
  }

  ${breakpointDown(Breakpoints.tabletPortrait.lower)`
    width: ${p =>
      p.size === "half"
        ? `calc(50% - ${Space.default.number / 2}rem)`
        : "100%"};
    & + & {
      margin-top: ${Space.xxLarge.rem};
    }
  `}

  img {
    vertical-align: bottom;
  }
`;
