import React from "react";
import { useInView } from "react-hook-inview";
import { Wrapper } from "./styled";
import { ModuleSizeType } from "../../../types";

interface ModuleWrapperProps {
  children: React.ReactNode;
}

const ModuleWrapper = ({ children }: ModuleWrapperProps) => {
  const [ref, isVisible] = useInView({
    unobserveOnEnter: true,
    threshold: 0.2,
  });
  return (
    <Wrapper isVisible={isVisible} ref={ref}>
      {children}
    </Wrapper>
  );
};

export default ModuleWrapper;
