import React from "react";
import { IconProps } from "../../models";

const ArrowRight = ({ color }: IconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42.26 10.53">
    <polyline points="37 10.53 42.26 5.26 37 0" fill={color} />
    <line
      x1="38"
      y1="5.26"
      y2="5.26"
      fill="none"
      stroke={color}
      strokeMiterlimit="10"
      className="line"
      style={{ transformOrigin: "100%" }}
    />
  </svg>
);

export default ArrowRight;
